import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { css } from 'emotion'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { Layout as _Layout, Text } from '../constants'
import Content, { HTMLContent } from '../components/Content'

const styles = {
  container: {
    paddingTop: 80,
    paddingBottom: _Layout.EDGE_PADDING,
    paddingLeft: _Layout.EDGE_PADDING * 1.5,
    paddingRight: _Layout.EDGE_PADDING * 1.5,
  },
  title: {
    fontSize: Text.HEADER,
    padding: 0,
    margin: '0px !important',
  },
  date: {
    fontWeight: 'bold',
  },
  titleSection: {
    marginBottom: _Layout.EDGE_PADDING,
    maxWidth: '83%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className={css(styles.container)}>
      {helmet || ''}
      <div className="container content">
        <div className={css(styles.wrapper)}>
          <div className={css(styles.titleSection)}>
            <h1 className={css(styles.title)}>{title}</h1>
            <small className={css(styles.date)}>{date}</small>
            <p>{description}</p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.any,
}

const BlogPost = ({ data }) => {
  const {
    markdownRemark: { frontmatter, html },
    navbarData,
    footerData,
  } = data

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <BlogPostTemplate
        content={html}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        helmet={<Helmet title={`${frontmatter.title} | Blog`} />}
        tags={frontmatter.tags}
        date={frontmatter.date}
        title={frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
